import { JSX, Match, Show, Switch, createEffect, createSignal, on, onMount } from "solid-js";
// import { AiOutlineCloseCircle } from "solid-icons/ai";
import { useJulep } from "~/context/JulepProvider";
import { usePlayground } from "~/context/PlaygroundProvider";
import { JulepAIChatMessageRole, OpenAIChatMessage } from "~/utils/OpenAI";
import { Switch as ArkSwitch } from "../ui/switch";
import CloseCircle from "../icons/CloseCircle";

type Props = {
  message: OpenAIChatMessage;
  isLast: () => boolean;
};

function RoleSelect(props: { role: JulepAIChatMessageRole; onChange: (role: JulepAIChatMessageRole) => void }) {
  return (
    <select
      name="role"
      id="role"
      value={props.role}
      class="w-28 resize-none rounded bg-[#F4F4F4] p-1 font-medium uppercase text-gray-700 focus:border-transparent focus:bg-white focus:outline-none focus:ring-1 focus:ring-blue-600"
      onChange={(e) => {
        props.onChange(e.target.value as JulepAIChatMessageRole);
      }}
    >
      <option value="user" class="normal-case">
        User
      </option>
      <option value="assistant" class="normal-case">
        Assistant
      </option>
      <option value="system" class="normal-case">
        System
      </option>
    </select>
  );
}

function UserNameSelect(props: { onChange: (name: string) => void }) {
  const [{ userNames }] = useJulep();

  return (
    <Show
      when={userNames().length > 1}
      fallback={<div class="mt-4 w-32 font-medium uppercase">{userNames()[0].name}</div>}
    >
      <select
        name="userName"
        id="userName"
        class="mt-3 w-36 resize-none rounded bg-[#F4F4F4] p-1 font-medium uppercase text-gray-700 focus:border-transparent focus:bg-white focus:outline-none focus:ring-1 focus:ring-blue-600"
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      >
        {userNames().map((userName, i) => (
          <option value={userName.id} class="normal-case">
            {userName.name}
          </option>
        ))}
      </select>
    </Show>
  );
}

function SystemNameSelect(props: { onChange: (name: string) => void; name: string }) {
  return (
    <select
      name="name"
      id="name"
      class="w-36 mt-3 resize-none rounded bg-[#F4F4F4] p-1 font-medium uppercase text-gray-700 focus:border-transparent focus:bg-white focus:outline-none focus:ring-1 focus:ring-blue-600"
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
      value={props.name}
    >
      <option value={"information"} class="normal-case">
        Information
      </option>
      <option value={"thought"} class="normal-case">
        Thought
      </option>
    </select>
  );
}

export default function PlaygroundMessage(props: Props) {
  const [{ showConversations }] = usePlayground();
  const [
    { assistantName, userNames, isContinue, messages },
    { updateMessageContent, removeMessage, updateMessageRole, updateMessageName, toggleIsContinue },
  ] = useJulep();
  const [focus, setFocus] = createSignal(false);
  let textAreaRef: HTMLTextAreaElement | undefined;

  // FIXME:
  onMount(() => {
    if (textAreaRef && props.message.role === "user") {
      textAreaRef.focus();
    }
  });

  const handleContentChange: JSX.ChangeEventHandlerUnion<HTMLTextAreaElement, Event> | undefined = (e) => {
    const message = props.message;
    if (e.target.value === message.content || message.id === undefined) return;

    updateMessageContent(message.id, e.target.value);
    if (textAreaRef) {
      textAreaRef.style.height = "40px";
      textAreaRef.style.height = `${textAreaRef.scrollHeight}px`;
    }
  };

  createEffect(
    on([messages], () => {
      const resize = () => {
        if (textAreaRef) {
          textAreaRef.style.height = "40px";
          textAreaRef.style.height = `${textAreaRef.scrollHeight}px`;
        }
      };

      resize();

      // Handle the 300ms delay on showing the conversation
      const timeout = setTimeout(() => {
        resize();
      }, 300);
    })
  );

  const handleRemove = () => {
    if (props.message.id === undefined) return;

    removeMessage(props.message.id);
  };

  const handleUpdateRole = (role: JulepAIChatMessageRole) => {
    if (props.message.id === undefined) return;

    updateMessageRole(props.message.id, role);
  };

  const handleUpdateName = (nameId: string) => {
    if (props.message.id === undefined) return;

    updateMessageName(props.message.id, nameId);
  };

  return (
    <div
      classList={{
        "group flex flex-row items-start space-x-4 border-b border-gray-300 p-4 transition-all hover:bg-gray-100": true,
        "bg-gray`-100": focus(),
      }}
      //   onFocus={() => setFocus(true)}
      //   onBlur={() => setFocus(false)}
    >
      <div class="mt-3">
        <RoleSelect onChange={(role) => handleUpdateRole(role)} role={props.message.role} />
      </div>
      <div class="w-32">
        <Switch>
          <Match when={props.message.role === "user"}>
            <UserNameSelect onChange={handleUpdateName} />
          </Match>
          <Match when={props.message.role === "assistant"}>
            <div class="mt-4 w-32 font-medium uppercase">{assistantName()}</div>
          </Match>
          <Match when={props.message.role === "system"}>
            <SystemNameSelect name={props.message.name || ""} onChange={handleUpdateName} />
          </Match>
        </Switch>
      </div>

      <div class="flex basis-11/12 flex-col items-end justify-end">
        <textarea
          class="text-md w-full resize-none rounded bg-transparent p-4 text-gray-700 focus:border-transparent focus:bg-white focus:outline-none focus:ring-1 focus:ring-blue-600 whitespace-pre-wrap"
          value={props.message.content}
          onChange={handleContentChange}
          //   onChange={handleContentChange}
          placeholder={`Enter ${props.message.role} message here`}
          ref={textAreaRef}
        />
        <Show
          when={
            (props.message.role === "assistant" ||
              (props.message.role === "system" && props.message.name === "thought")) &&
            props.isLast()
          }
        >
          <ArkSwitch.Root
            className="mt-2"
            checked={isContinue()}
            onCheckedChange={() => toggleIsContinue(props.message.id!)}
          >
            {(api) => (
              <>
                <ArkSwitch.Label class="text-base font-normal text-[#464646]">Continue Inline</ArkSwitch.Label>
                <ArkSwitch.Control
                  classList={{
                    "bg-green-400": api().isChecked,
                  }}
                >
                  <ArkSwitch.Thumb />
                </ArkSwitch.Control>
              </>
            )}
          </ArkSwitch.Root>
        </Show>
      </div>

      {/* <div class="flex basis-1/12 justify-center">
        <button
          class={`text-transparent transition-all group-hover:text-gray-300 hover:text-gray-700 focus:outline-none`}
          onClick={handleRemove}
        >
          <CloseCircle />
        </button>
      </div> */}
    </div>
  );
}
