import { JSX, createEffect, createSignal } from "solid-js";
import { useAuth } from "~/context/AuthProvider";
import { useJulep } from "~/context/JulepProvider";
import { Textarea as ArkTextarea } from "~/components/ui/textarea";

type Props = {
  title: string;
  class?: string;
  placeholder?: string;
  value?: string;
  onChange?: (
    e: Event & {
      currentTarget: HTMLTextAreaElement;
      target: HTMLTextAreaElement;
    }
  ) => void;
};

export default function TextArea(props: Props) {
  const [{ apiKey }] = useAuth();
  const [{ systemMessage }] = useJulep();
  const [text, setText] = createSignal("hi there");

  const handleChange: JSX.ChangeEventHandlerUnion<HTMLTextAreaElement, Event> | undefined = (e) => {
    props.onChange?.(e);
  };

  return (
    <div class={`flex flex-col ${props.class}`}>
      <label class="text-sm font-semibold text-gray-700">{props.title}</label>
      <ArkTextarea
        class="text-md mt-2 flex-1 resize-none text-gray-700 focus:outline-none rounded-md border border-gray-300 p-2 disabled:text-opacity-40 disabled:cursor-not-allowed"
        placeholder={props.value}
        value={props.value}
        rows={17}
        onChange={handleChange}
        disabled={!apiKey()}
      />
    </div>
  );
}
