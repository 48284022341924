import { JSX } from "solid-js";
import { useAuth } from "~/context/AuthProvider";

type Props = {
  title: string;
  class?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: any) => void;
};

export default function Input(props: Props) {
  const [{ jwt }] = useAuth();

  const handleChange: JSX.ChangeEventHandlerUnion<HTMLInputElement, Event> | undefined = (e) => {
    props.onChange?.(e);
  };

  return (
    <div class={`flex flex-col ${props.class}`}>
      <label class="text-sm font-semibold text-gray-700">{props.title}</label>
      <input
        class="text-md mt-2 flex-1 resize-none rounded border border-gray-300 p-2 text-gray-700 focus:outline-none disabled:text-opacity-40 disabled:cursor-not-allowed"
        placeholder={props.placeholder}
        value={props.value}
        onChange={handleChange}
        disabled={!jwt()}
      />
    </div>
  );
}
