import { createEffect, createSignal } from "solid-js";
import { useAuth } from "~/context/AuthProvider";
import { OpenAIChatModels, OpenAIModel } from "~/utils/OpenAI";

/*
  Simple hook to fetch models from the API
*/
export default function useModels() {
  const [{ jwt }] = useAuth();
  const [models, setModels] = createSignal<OpenAIModel[]>([]);
  const [loadingModels, setLoadingModels] = createSignal(false);

  createEffect(() => {
    if (!jwt()) {
      return setModels(Object.values(OpenAIChatModels));
    }

    // const fetchModels = async () => {
    //   setLoadingModels(true);
    //   const models = await fetch("/models", {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       authorization: `Bearer ${token}`,
    //     },
    //   })
    //     .then((res) => res.json())
    //     .then((res) => res.chatModels);

    //   setModels(models || []);
    //   setLoadingModels(false);
    // };

    // fetchModels();
  });

  return { models, loadingModels };
}
