import { JSX, Show, createSignal } from "solid-js";
import { PlusCircle, X, Box } from "lucide-solid";

import { useAuth } from "~/context/AuthProvider";

type Props = {
  class?: string;
};

export default function AddTokenModal(props: Props) {
  const [{ apiKey }] = useAuth();
  const [open, setOpen] = createSignal(false);
  const [input, setInput] = createSignal(""); // FIXME:

  const handleInput = (e: any) => {
    setInput(e.target.value);
  };

  const handleSubmit: JSX.EventHandlerUnion<HTMLFormElement, Event & { submitter: HTMLElement }> | undefined = (
    e: any
  ) => {
    e.preventDefault();
    // addToken(input());
    setOpen(false);
  };

  return (
    <>
      <Show when={!apiKey()}>
        <button
          class={` flex flex-row items-center justify-center rounded-full bg-[#FFC776] p-3 text-sm font-semibold hover:bg-[#FFD291] ${props.class}`}
          onClick={() => setOpen(true)}
        >
          <PlusCircle class="mr-2" />
          Add API token to edit
        </button>
      </Show>

      <button
        class={`flex items-center gap-x-1 rounded-full bg-[#FFC776] p-4 text-white hover:bg-[#FFD291] md:hidden ${props.class}`}
        onClick={() => setOpen(true)}
      >
        <Box />
        API token
      </button>

      <Show when={open()}>
        <div class="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50 transition-all">
          <div class="relative m-4 max-w-2xl rounded-2xl bg-white p-8 shadow-xl">
            <div class="absolute right-0 top-0 m-2">
              <button class="rounded p-2 text-primary hover:bg-primary/50" onClick={() => setOpen(false)}>
                <X />
              </button>
            </div>
            <h1 class="text-2xl font-bold text-primary">Add API token</h1>
            <p class="mt-4 text-lg text-primary/80">
              You can get your API token from the{" "}
              <a href="https://console.julep.ai" target="_blank" rel="noreferrer" class="text-primary underline">
                JulepAI dashboard
              </a>
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="NhU98cac878..."
                class="mt-4 w-full rounded border border-[#8d8d8d] bg-white p-2 text-primary outline-none"
                onChange={handleInput}
                value={input()}
              />
              <div class="mt-4 flex justify-center">
                <button type="submit" class="rounded-full bg-[#FFC776] px-4 py-2 font-bold hover:bg-[#FFD291]">
                  Add this token
                </button>
              </div>
            </form>
          </div>
        </div>
      </Show>
    </>
  );
}
