import { Copy } from "lucide-solid";
import { Show } from "solid-js";

import { useJulep } from "~/context/JulepProvider";
import { Skeleton } from "~/components/ui/skeleton";
import { useAuth } from "~/context/AuthProvider";

type Props = {};

export default function ApiKey({}: Props) {
  const [{ apiKey }] = useAuth();
  const [_, { toast }] = useJulep();

  const handleCopyToken = () => {
    // toast.success("Copied to clipboard!", {
    //   position: "top-center",
    //   autoClose: 2000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   draggable: true,
    //   theme: "colored",
    // });
    toast().success({
      title: "Copied to clipboard",
    });
    navigator.clipboard.writeText(apiKey());
  };

  return (
    <div class="py-2">
      <h3 class="mb-2 text-xs font-medium">YOUR API TOKEN</h3>
      <Show when={!!apiKey()} fallback={<Skeleton>This is some content</Skeleton>}>
        <div class="border-tertiary group flex flex-row items-center rounded-md border">
          <div class="relative flex w-40 select-none items-center gap-3 overflow-hidden text-ellipsis whitespace-nowrap rounded p-3 text-primary/80 transition-colors ">
            {apiKey()}
          </div>
          <div class="ml-2 flex w-fit grow flex-row items-center gap-1">
            <button class="text-xl opacity-60 transition-opacity hover:opacity-100" onClick={handleCopyToken}>
              <Copy class="w-4 h-4" />
            </button>
          </div>
        </div>
      </Show>
    </div>
  );
}
