import { Show } from "solid-js";
import { Spinner, SpinnerType } from "solid-spinner";
import ConfigSidebar from "~/components/playground/ConfigSidebar";
import PlaygroundMessages from "~/components/playground/PlaygroundMessages";
import SystemMessage from "~/components/playground/SystemMessage";
import { useJulep } from "~/context/JulepProvider";

export default function Playground() {
  const [{ initialized }] = useJulep();
  return (
    <div class="flex h-[calc(100vh-60px)] max-h-[calc(100vh-60px)] grow flex-row">
      <div class="flex grow flex-col items-stretch md:flex-row">
        <div class="flex grow">
          <SystemMessage />
        </div>
        <div class="flex grow basis-7/12 w-full overflow-hidden ">
          <Show
            when={initialized()}
            fallback={
              <div class="flex h-full w-full items-center justify-center">
                <Spinner type={SpinnerType.spinningCircles} />
              </div>
            }
          >
            <PlaygroundMessages />
          </Show>
        </div>
      </div>
      <ConfigSidebar />
    </div>
  );
}
