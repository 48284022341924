// import { IoAdd } from "solid-icons/io";
import { PlusCircle } from "lucide-solid";
import { useAuth } from "~/context/AuthProvider";
import { useJulep } from "~/context/JulepProvider";

type Props = {};

export default function AddMessage({}: Props) {
  const [{ userNames, loading }, { addMessage }] = useJulep();
  const [{ apiKey }] = useAuth();

  return (
    <button
      class="flex cursor-pointer flex-row gap-x-4 p-4 text-gray-700 hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
      onClick={() => addMessage("", "user", userNames()[0].name)}
      disabled={!apiKey() || loading()}
    >
      <PlusCircle size={24} />
      <span class="font-medium">Add Message</span>
    </button>
  );
}
