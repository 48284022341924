import { JSX, Show, createEffect } from "solid-js";
// import { FaSolidTrashCan } from "solid-icons/fa";
import { useAuth } from "~/context/AuthProvider";
import { useJulep } from "~/context/JulepProvider";

type Props = {
  class?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  id: string;
};

export default function NameInput(props: Props) {
  const [{ jwt }] = useAuth();
  const [{ userNames }, { deleteUserName }] = useJulep();

  const handleChange: JSX.ChangeEventHandlerUnion<HTMLInputElement, Event> | undefined = (e) => {
    props.onChange?.(e.target.value);
  };

  const handleDelete = () => {
    deleteUserName(props.id);
  };

  return (
    <div
      class={`group relative flex flex-col rounded-md border border-gray-300 focus-within:border-blue-500 ${props.class}`}
    >
      <input
        class="rounded-md p-2 focus:outline-none disabled:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50"
        placeholder={props.placeholder}
        value={props.value}
        onChange={handleChange}
        disabled={!jwt()}
      />
      <div class="absolute right-0 top-0 p-2">
        {/* <Show when={userNames().length > 1}>
          <FaSolidTrashCan class="h-5 w-5 cursor-pointer text-gray-500  hover:text-red-500" onClick={handleDelete} />
        </Show> */}
      </div>
    </div>
  );
}
