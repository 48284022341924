import { useJulep } from "~/context/JulepProvider";
import Input from "../input/Input";
import NamesInput from "../input/NamesInput";
import TextArea from "../input/TextArea";
import SidebarAvatar from "../Sidebar/SidebarAvatar";

type Props = {};

export default function SystemMessage({}: Props) {
  const [
    { systemMessage, assistantName, userNames },
    { updateSystemMessage, updateAssistantName, updateUsernames, deleteUserName },
  ] = useJulep();

  return (
    <div class="grow space-y-6 border-r p-6 flex flex-col items-stretch justify-between">
      <div>
        <Input
          title="Name of the assistant"
          value={assistantName()}
          onChange={(e) => updateAssistantName(e.target.value)}
        />
        <NamesInput title="Name of the user" />

        <TextArea
          title="Situation"
          class="grow"
          //   placeholder="You are a helpful assistant."
          value={systemMessage().content}
          onChange={(e) => updateSystemMessage(e.target.value)}
        />
      </div>
      <SidebarAvatar />
    </div>
  );
}
