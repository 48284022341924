import { For, Show, createEffect, createSignal, onCleanup, onMount, createMemo } from "solid-js";
import { createShortcut } from "@solid-primitives/keyboard";

import { useAuth } from "~/context/AuthProvider";
import { useJulep } from "~/context/JulepProvider";
import PlaygroundMessage from "./PlaygroundMessage";
import AddMessage from "./AddMessage";
import AddTokenModal from "../auth/AddTokenModal";
import ClearConversationModal from "../ClearConversationModal";
import { useAction, useSubmission } from "@solidjs/router";

type Props = {};

export default function PlaygroundMessages({}: Props) {
  const [{ messages, loading }, { submitAction }] = useJulep();
  const [{ apiKey }] = useAuth();
  let messageContainer: HTMLDivElement | undefined;
  const [prevMessageLength, setPrevMessageLength] = createSignal(0);
  const [scrolling, setScrolling] = createSignal(false);

  const submit = useAction(submitAction);
  const submitStatus = useSubmission(submitAction);

  // Hotkeys
  createShortcut(["Mod", "Enter"], () => {
    submit();
  });

  createEffect(() => {
    if (!messages()) return;
    if (messages().length != prevMessageLength()) {
      setPrevMessageLength(messages().length);
    }

    if (messageContainer && (!scrolling() || messages().length != prevMessageLength())) {
      messageContainer.scrollTop = messageContainer.scrollHeight;
    }
  });

  // Command Enter to submit
  //   useEffect(() => {
  //     const handleKeyDown = (e: KeyboardEvent) => {
  //       if (e.key === "Enter" && e.metaKey) {
  //         submit();
  //       }
  //     };

  //     window.addEventListener("keydown", handleKeyDown);

  //     return () => {
  //       window.removeEventListener("keydown", handleKeyDown);
  //     };
  //   }, [submit]);

  // Scroll handling for auto scroll
  onMount(() => {
    const handleScroll = () => {
      if (messageContainer) {
        if (messageContainer.scrollTop < messageContainer.scrollHeight - messageContainer.offsetHeight) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      }
    };

    if (messageContainer) {
      messageContainer.addEventListener("scroll", handleScroll);
    }

    onCleanup(() => {
      if (messageContainer) {
        messageContainer.removeEventListener("scroll", handleScroll);
      }
    });
  });

  const contentEmpty = () => messages().some((message) => message.content.trim() === "");

  return (
    <div class="flex grow flex-col justify-between md:grow">
      <div class="my-4 flex flex-col items-stretch overflow-y-auto px-4" ref={messageContainer}>
        <For each={messages()}>
          {(message, i) => {
            const isLast = () => messages().length === i() + 1;
            return <PlaygroundMessage message={message} isLast={isLast} />;
          }}
        </For>
        <AddMessage />
      </div>

      <div class="flex justify-between gap-3 bg-white p-4">
        <ClearConversationModal />

        <Show when={apiKey()} fallback={<AddTokenModal />}>
          <>
            <a class="submit-tooltip">
              <button
                class="w-[80px] rounded-full bg-[#FFC776] font-semibold p-3 hover:bg-[#FFD291] disabled:cursor-not-allowed disabled:opacity-40"
                onClick={() => submit()}
                // disabled={contentEmpty() && !isContinue()}
              >
                <Show when={loading()} fallback="Submit">
                  <div class="mx-auto h-5 w-5 animate-spin rounded-full border-b-2 border-white" />
                </Show>
              </button>
              {/* <Tooltip
                anchorSelect={`.submit-tooltip`}
                content="Submit with Command (CTRL) + Enter"
                place="top"
                style={{
                  fontSize: 14,
                }}
              /> */}
            </a>
          </>
        </Show>
      </div>
    </div>
  );
}
