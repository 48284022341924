import { Show, createSignal } from "solid-js";
import { X } from "lucide-solid";
import { useAuth } from "~/context/AuthProvider";
import { useJulep } from "~/context/JulepProvider";

type Props = {
  className?: string;
};

export default function ClearConversationModal({ className }: Props) {
  const [{ apiKey }] = useAuth();

  const [_, { clearConversation }] = useJulep();
  const [open, setOpen] = createSignal(false);
  const [input, setInput] = createSignal(apiKey());

  const handleClear = () => {
    clearConversation();
    setOpen(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // addToken(input());
    setOpen(false);
  };

  return (
    <>
      <button
        class={` flex h-12 flex-row items-center justify-center rounded-full bg-primary p-3 text-sm font-bold hover:bg-secondary disabled:cursor-not-allowed disabled:opacity-40 ${className}`}
        onClick={() => setOpen(true)}
        disabled={!apiKey()}
      >
        <img class="mr-2" src="/images/trash.svg" alt="trash" width={18} height={18} />
        Clear
      </button>

      <Show when={open()}>
        <div class="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50 transition-all">
          <div class="relative m-4 max-w-2xl rounded-2xl bg-white p-14 shadow-xl">
            <div class="absolute right-0 top-0 m-2">
              <button class="rounded p-2 text-primary hover:bg-primary/50" onClick={() => setOpen(false)}>
                <X />
              </button>
            </div>
            <h1 class="text-2xl font-bold text-primary">Your entire conversation will be deleted</h1>
            <div class="mt-8 flex justify-center space-x-4">
              <button
                type="submit"
                class="rounded-full bg-[#FFC776] px-6 py-3 font-semibold hover:bg-[#FFD291]"
                onClick={handleClear}
              >
                Clear
              </button>
              <button type="submit" class="rounded-full px-4 py-2 font-semibold" onClick={() => setOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Show>
    </>
  );
}
