import { createAsync, useAction } from "@solidjs/router";
import { Avatar } from "../ui/avatar";
import * as Menu from "../ui/menu";
import { getSessionUser, signOut as signOut_ } from "~/api";
import { createMemo } from "solid-js";
import { LogOutIcon } from "lucide-solid";

export default function SidebarAvatar() {
  const user = createAsync(() => getSessionUser());
  const signOut = useAction(signOut_);

  const name = createMemo(() => {
    return user()?.user_metadata?.full_name;
  });

  const email = createMemo(() => {
    return user()?.email;
  });
  return (
    <Menu.Root>
      <Menu.Trigger class="flex flex-row items-center gap-2 overflow-hidden">
        <Avatar name={name()} />
        <span class="truncate w-40 text-left">{name()}</span>
      </Menu.Trigger>
      <Menu.Positioner>
        <Menu.Content>
          <Menu.ItemGroup id="group-1">
            <Menu.ItemGroupLabel for="group-1" class="flex flex-col">
              <div>{name()}</div>
              <div class="text-sm font-normal">{email()}</div>
            </Menu.ItemGroupLabel>
            <Menu.Separator />
            <Menu.Item id="logout" onClick={signOut}>
              <div class="flex flex-row text-red-500 items-center gap-2">
                <LogOutIcon class="stroke-red-500" />
                Logout
              </div>
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu.Content>
      </Menu.Positioner>
    </Menu.Root>
  );
}
