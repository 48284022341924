import { For } from "solid-js";
import { v4 as uuid } from "uuid";
// import { IoAdd } from "solid-icons/io";

import { useAuth } from "~/context/AuthProvider";
import { useJulep } from "~/context/JulepProvider";
import NameInput from "./NameInput";

type Props = {
  title: string;
  class?: string;
};

export default function NamesInput(props: Props) {
  const [{ userNames }, { setUsernames, updateUsernames }] = useJulep();
  const [{ jwt }] = useAuth();

  const handleNameChange = (id: string, name: string) => {
    const newValue = userNames().map((userName) => {
      if (userName.id === id) {
        return { ...userName, name };
      }
      return userName;
    });
    updateUsernames(newValue);
  };

  const handleAddUser = () => {
    setUsernames([...userNames(), { id: uuid(), name: "" }]);
  };

  return (
    <div class={`flex flex-col ${props.class}`}>
      <label class="text-sm font-semibold text-gray-700">{props.title}</label>

      <For each={userNames()}>
        {(name) => (
          <NameInput
            id={name.id}
            class="text-md mt-2 flex-1 resize-none rounded text-gray-700 focus:outline-none"
            placeholder="Name"
            value={name.name}
            onChange={(value) => handleNameChange(name.id, value)}
          />
        )}
      </For>
      <button
        onClick={handleAddUser}
        class="mt-4 flex items-center justify-start disabled:cursor-not-allowed disabled:opacity-50"
        disabled={!jwt()}
      >
        {/* <IoAdd size={13.5} /> <span class="ml-1 text-sm">Add User</span> */}
      </button>
    </div>
  );
}
