import { Slider as ArkSlider } from "../ui/slider";

import { useAuth } from "~/context/AuthProvider";
import { Tooltip } from "../ui/tooltip";
import { sentenceCase } from "change-case";

type Props = {
  label: string;
  range: [number, number];
  step: number;
  value: number;
  onChange: (value: number) => void;
  tooltip?: string;
};

export default function Slider(props: Props) {
  const [{ jwt }] = useAuth();

  const handleChange = (data: { value: number[] }) => {
    // Clamp value to range
    const value = Math.min(Math.max(Number(data.value[0]), props.range[0]), props.range[1]);

    // If step < 1 then round
    const roundedValue = props.step < 1 ? Math.round(value) : value;

    // console.log({value, roundedValue})

    // if (isNaN(roundedValue)) return;

    props?.onChange(value);
  };

  return (
    // <div className="flex flex-col items-stretch">
    //   <div className="flex flex-row items-center justify-between py-1">
    //     <div
    //       className={`flex basis-9/12 text-xs font-medium text-gray-700 ${
    //         token ? "" : "cursor-not-allowed opacity-40"
    //       }`}
    //     >
    //       {tooltip && (
    //         <>
    //           <a className={`${label}-tooltip`}>
    //             <MdInfoOutline className="mr-1" size={16} />
    //           </a>
    //           <Tooltip
    //             anchorSelect={`.${label}-tooltip`}
    //             content={tooltip}
    //             place="left"
    //             style={{
    //               width: 256,
    //               fontSize: 14,
    //             }}
    //           />
    //         </>
    //       )}
    //       {sentenceCase(label).toUpperCase()}
    //     </div>
    //     <input
    //       className="text-md w-14 rounded px-1 text-right text-gray-700 focus:outline focus:outline-blue-500 disabled:cursor-not-allowed disabled:opacity-40"
    //       type="text"
    //       value={value}
    //       onChange={handleChange}
    //       disabled={!token}
    //     />
    //   </div>

    //   <ReactSlider
    //     className="mt-2 flex-1 disabled:cursor-not-allowed disabled:opacity-40"
    //     thumbClassName="bg-white border-2 border-gray-300 text-white h-4 w-4 -translate-y-[calc((1rem-.25rem)/2)] rounded-full"
    //     trackClassName="bg-gray-300 h-1 rounded"
    //     min={range[0]}
    //     max={range[1]}
    //     step={step}
    //     value={value}
    //     onChange={onChange}
    //     disabled={!token}
    //   />
    // </div>
    <ArkSlider.Root
      min={props.range[0]}
      max={props.range[1]}
      step={props.step}
      value={[props.value]}
      disabled={!jwt()}
      onValueChangeEnd={handleChange}
    >
      <div class="flex flex-row justify-between">
        <Tooltip.Root
          positioning={{
            placement: "left-start",
          }}
        >
          <Tooltip.Trigger>
            <ArkSlider.Label class="text-xs font-medium font-gray-700 cursor-pointer">
              {sentenceCase(props.label).toUpperCase()}
            </ArkSlider.Label>
          </Tooltip.Trigger>
          <Tooltip.Positioner>
            <Tooltip.Arrow>
              <Tooltip.ArrowTip />
            </Tooltip.Arrow>
            <Tooltip.Content class="z-100">{props.tooltip}</Tooltip.Content>
          </Tooltip.Positioner>
        </Tooltip.Root>
        <ArkSlider.ValueText />
      </div>

      <ArkSlider.Control>
        <ArkSlider.Track class="h-1">
          <ArkSlider.Range class="bg-gray-300" />
        </ArkSlider.Track>
        <ArkSlider.Thumb index={0} class="border-gray-300 h-4 w-4 cursor-pointer" />
      </ArkSlider.Control>
    </ArkSlider.Root>
  );
}
