import { Skeleton } from "@kobalte/core";
import { useJulep } from "~/context/JulepProvider";
import useModels from "../hooks/useModels";
import { useAuth } from "~/context/AuthProvider";
import { OpenAIChatModels, OpenAIConfig } from "~/utils/OpenAI";
import ApiKey from "../chat/sidebar/buttons/ApiKey";
import Slider from "../input/Slider";
import { Suspense } from "solid-js";
// import Dropdown from "../input/Dropdown";

type Props = {};

export default function ConfigSidebar({}: Props) {
  const [{ config }, { updateConfig }] = useJulep();
  const { models, loadingModels } = useModels();
  const [{ jwt }] = useAuth();

  const handleUpdateConfig = <K extends keyof OpenAIConfig>(id: K, value: OpenAIConfig[K] | boolean | undefined) => {
    updateConfig({
      [id]: value,
    });
  };

  return (
    <div class="hidden min-w-[240px] flex-col items-stretch gap-y-4 border-l p-4 md:flex">
      <ApiKey />

      {/* <Dropdown
        label="Model"
        options={
          loadingModels()
            ? []
            : (models().map(({ id }) => ({ label: id, value: id })) as any)
        }
        value={config().model}
        onSelect={(option) => handleUpdateConfig("model", option)}
      /> */}

      <Slider
        label="temperature"
        range={[0, 1]}
        step={0.01}
        value={config().temperature as number}
        onChange={(value: OpenAIConfig["temperature"]) => handleUpdateConfig("temperature", value)}
        tooltip="Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive. Higher temperature results in more random completions."
      />
      <Slider
        label="max_tokens"
        range={[0, OpenAIChatModels[config().model].maxLimit || 8192]}
        step={1}
        value={config().max_tokens as number}
        onChange={(value: OpenAIConfig["max_tokens"]) => handleUpdateConfig("max_tokens", value)}
        tooltip="The maximum number of tokens to generate. Requests can use up to 800 tokens shared between prompt and completion. (One token is roughly 4 characters for normal English text.)"
      />
      <Slider
        label="top_p"
        range={[0, 1]}
        step={0.01}
        value={config().top_p as number}
        onChange={(value: OpenAIConfig["top_p"]) => handleUpdateConfig("top_p", value)}
        tooltip="An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered."
      />
      <Slider
        label="frequency_penalty"
        range={[-1, 1]}
        step={0.01}
        value={config().frequency_penalty as number}
        onChange={(value: OpenAIConfig["frequency_penalty"]) => handleUpdateConfig("frequency_penalty", value)}
        tooltip="Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim."
      />
      <Slider
        label="presence_penalty"
        range={[-1, 1]}
        step={0.01}
        value={config().presence_penalty as number}
        onChange={(value: OpenAIConfig["presence_penalty"]) => handleUpdateConfig("presence_penalty", value)}
        tooltip="The scale of the penalty applied if a token is already present at the same index in the text so far. Set this to zero if you want the model to generate completely new content, ignoring any similarities to the prompt."
      />
      <Slider
        label="repetition_penalty"
        range={[0, 2]}
        step={0.01}
        value={config().repetition_penalty as number}
        onChange={(value: OpenAIConfig["repetition_penalty"]) => handleUpdateConfig("repetition_penalty", value)}
        tooltip="The scale of the penalty for repeated tokens. 1.0 means no penalty. Lower values encourage the model to generate new tokens"
      />
      {/* <Slider
        label="length_penalty"
        range={[0, 1]}
        step={0.01}
        value={config().length_penalty as number}
        onChange={(value: OpenAIConfig["length_penalty"]) =>
          handleUpdateConfig("length_penalty", value)
        }
        tooltip="The scale of the penalty for repeated tokens. 1.0 means no penalty. Lower values encourage the model to generate new tokens"
      /> */}

      {/* <div class="flex flex-col items-stretch">
        <label for="stop" class="flex basis-9/12 select-none text-xs font-medium uppercase text-gray-700">
          Stop
        </label>
        <input
          name="stop"
          id="stop"
          type="text"
          value={config().stop}
          onChange={(e) => handleUpdateConfig("stop", e.target.value === "" ? [] : e.target.value.split(","))}
          class="text-md mt-1 rounded border border-gray-300 px-1 text-right text-gray-700 focus:outline focus:outline-blue-500"
          disabled={!token()}
        />
      </div> */}
    </div>
  );
}
